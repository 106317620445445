<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcLocationDetails }}</h1>
      <BackJump @reload_host="handleLocationChange()" :obj="backJumpObj" :vis="true" :i18n="translations.component">
      </BackJump>
    </page-header>
    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-2">
            <h4>{{ detail.org_name }}</h4>
          </header>
          <div class="d-flex row details">
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcAssignedNumber }}:</strong>
                <span>{{ detail.camp_assigned_number }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcInstitutionType }}:</strong>
                <span>{{ detail.org_type_name }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcMembersRequired }}: </strong>
                <span>{{ detail.members_required }}</span>
              </div>
            </div>
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcMemberType }}: </strong>
                <span>{{ detail.member_participation_type }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcWhereWeShouldBe }}: </strong>
                <span>{{ detail.where_we_should_be_flag ? translations.tcYes : translations.tcNo }}</span>
              </div>
              <div class="detail full-detail">
                <strong>{{ translations.tcWebsite }}: </strong>
                <span>
                  <a target="_blank" v-if="detail.url" :href="websiteURL">{{ detail.url }}</a>
                </span>
              </div>
            </div>
            <div class="col">
              <div class="detail full-detail">
                <strong>{{ translations.tcOtherInformation }}: </strong>
                <span>{{ detail.note }}</span>
              </div>
            </div>
          </div>
          <b-button v-if="toggleMe && iCanSeeAny(secured_placement_location_edit_controls)" variant="primary"
            class="mr-2" @click="handleEditClick">{{ translations.tcEdit }}</b-button>
          <b-button v-if="toggleMe && iCanSeeAny(secured_placement_location_delete_controls)" variant="secondary"
            @click="handleDeleteClick">{{ translations.tcDelete }}</b-button>
        </div>
      </section>
    </page-body>
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.component"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJumpLocation.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'location-details',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      institutionType: '',
      toggle: true,
      toggleMe: true,
      backJumpObj: {
        link: '/programs/sd/location-profile',
        location: '',
        key: null,
      },
      secured_placement_location_delete_controls: {
        delete_aux_facility_placement_location_button: 'da5355c7-a402-4f42-baa9-749e6bced4ce',
        delete_scripture_distribution_placement_location_button: '6807b068-6f28-4016-833e-bb9b2ec78964',
      },
      secured_placement_location_edit_controls: {
        edit_aux_facility_placement_location_button: 'b9501e8f-1bee-4a89-b35e-627f25c9ff97',
        edit_scripture_distribution_placement_location_button: 'a8ec7a34-e8e3-4f54-aa69-100cf6f3f112',
      },
    }
  },
  methods: {
    ...mapActions({
      deleteLocation: 'scriptureDistribution/deleteLocation',
      loadLocationDetails: 'scriptureDistribution/loadLocationDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async pageLoad() {
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          await this.loadLocationDetails({ org_key: this.userSelectedLocationKey, org_type_key: this.userSelectedOrgTypeKey }),
          await (this.backJumpObj.location = this.detail.org_name),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleNameClick(key) {
      await this.setSelectedIndividualKey(key)
      this.$router.push('/programs/mb/member-profile/')
    },
    async handleEditClick() {
      this.$router.push('/programs/sd/location-profile/add-location/link')
    },
    async handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkToDelete,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          let payload = {
            loc_key: this.detail.plf_org_key,
            user: this.userDemograph.member_number,
          }
          this.deleteTheLocation(payload)
        }
      })
    },
    async deleteTheLocation(payload) {
      try {
        let result = null
        await Promise.all([this.setLoadingStatus(true), (result = await this.deleteLocation(payload))]).then(
          (result) => {
            this.setLoadingStatus(false)
            this.$swal({
              icon: result[1] === true ? 'success' : 'error',
              text: result[1] === true ? this.translations.tcLocationDeleted : this.translations.tcErrorDuringDeletion,
              confirmButtonText: this.translations.tcOk || 'Ok',
            }).then((result) => {
              if (result.value) {
                this.$router.push('/programs/sd/placement-locations')
              }
            })
          }
        )
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    updateDynamicText() {
      if (!this.translations.common) return false
      const instTypeGuid = this.detail.org_type_key
      const instType = this.translations.common['placement-facility-types'][instTypeGuid] || ''
      this.institutionType = instType ? instType : this.detail.org_type_name
      return true
    },
    async handleLocationChange() {
      await Promise.all([this.pageLoad()]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.updateDynamicText()
      })
    },
  },
  async created() {
    if (!this.userSelectedLocationKey) {
      this.$router.push('/programs/sd/placement-locations')
    }
    try {
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('back-jump-location', 'quick-jump-locations'),
        this.getComponentTranslations('common.member-types', 'common.placement-facility-types'),
        this.handleLocationChange(),
      ])
    } catch (e) {
      console.error('Error in LocationDetails.vue, created(), ', e)
    }
  },
  computed: {
    ...mapGetters({
      detail: 'scriptureDistribution/locationDetail',
      iCanSeeAny: 'user/iCanSeeAny',
      prefCulture: 'user/userPreferredCulture',
      userDemograph: 'user/demograph',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
      userSelectedOrgTypeKey: 'user/userSelectedOrgTypeKey',
    }),
    websiteURL() {
      if (!this.detail.url) {
        return ''
      }
      return this.detail.url.indexOf('http://') === -1 && this.detail.url.indexOf('https://') === -1
        ? `http://${this.detail.url}`
        : this.detail.url
    },
  },
  components: {
    BackJump: BackJump,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.service-times-presentations {
  .page-header h1 {
    margin-bottom: 50px;
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @include breakpoint(sm) {
    .section-1 {
      .ml-4 {
        margin-left: 0 !important;
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.details {
  margin-bottom: 50px;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .col {
    flex: 0 0 33.3333%;
    width: 33.3333%;

    @include breakpoint(sm) {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .detail {
    display: flex;
    align-items: flex-start;
    font-size: 15px;

    strong,
    span {
      display: block;
      color: $gray;
      letter-spacing: 1px;
      line-height: 28px;
    }

    strong {
      flex: 0 0 auto;
      width: 210px;
      padding-right: 10px;
      text-transform: uppercase;
      font-weight: 900;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    span {
      flex: 1 1 auto;
      align-self: flex-end;
    }

    &.full-detail {
      display: block;
    }
  }
}
</style>
